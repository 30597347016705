<template>
  <Skeleton
    :wrapperClass="wrapperClass"
    :gridClass="gridClass"
    ref="skeleton"
    :number="Math.ceil(options.slidesPerView)"
    v-if="products.length === 0"
  />

  <div v-else :class="wrapperClass">
    <div class="section-title-wrapper">
      <h2 class="section-title" v-text="title"></h2>

      <div class="dosiin_slider-controls" id="swiper-paginate">
        <div
          class="dosiin_slider-pagination swipable-slider_pagination swiper-button-pagination swiper-filter-nav-bar"
          :class="paginationClass"
        ></div>
      </div>
    </div>

    <div class="dosiin_product-highlight dosiin_position-relative">
      <Swiper
        :class="layout != 4 ? 'dosiin_position-unset' : 'overflow-unset'"
        :modules="modules"
        :slidesPerView="options.slidesPerView"
        :slidesPerGroup="options.slidesPerGroup"
        :spaceBetween="options.spaceBetween"
        :navigation="options.navigation"
        :pagination="pagination"
      >
        <SwiperSlide v-for="(product, index) in products" :key="index">
          <ProductItem :product="product" :showRating="true" />
        </SwiperSlide>

        <SwiperSlide
          class="last-slide"
          style="background-color: #fff"
          v-if="showSlideLink"
        >
          <div class="seemore-btn">
            <router-link :to="link">
              <i class="dsi dsi-act-arrow-forward" style="margin: auto"></i>
              <div class="primary-button">
                <span class="primary-link_text">Xem thêm</span>
              </div>
            </router-link>
          </div>
        </SwiperSlide>

        <div
          v-if="options.navigation"
          class="swiper-button-next dosiin_swiper-button dosiin_position-button-next"
        ></div>
        <div
          v-if="options.navigation"
          class="swiper-button-prev dosiin_swiper-button dosiin_position-button-prev"
        ></div>
      </Swiper>
    </div>

    <div v-if="showLink" class="seemore-btn more">
      <!-- <router-link class="primary-button button" :to="link" :title="title"> -->
      <a class="primary-button button" :href="link" :title="title">
        <span class="primary-link_text">Xem tất cả</span>
        <i class="dsi dsi-act-arrow-forward"></i>
      </a>
      <!-- </router-link> -->
    </div>
  </div>
</template>

<script>
import { toRefs, ref, watch } from "vue";
import ProductItem from "@/components/product/ProductItem";
import Skeleton from "@/components/skeletons/sliders/ProductsHorizon";
import { Pagination, Navigation } from "swiper";
export default {
  components: {
    ProductItem,
    Skeleton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    gridClass: {
      type: String,
      default: "grid-33",
    },
    products: {
      type: Array,
      require: true,
      default: [],
    },
    showLink: {
      type: Boolean,
      default: false,
    },
    showSlideLink: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: "/",
    },
    options: {
      type: Object,
      default: {
        slidesPerView: 2.5,
        slidesPerGroup: 3,
        spaceBetween: 16,
        navigation: false,
      },
    },
  },
  setup(props) {
    const options = {};
    const skeleton = ref(null);
    const paginationClass =
      "dosiin_slider-pagination-" + btoa(Math.random().toString()).substr(10, 5);
    const pagination = {
      el: "." + paginationClass,
      clickable: true,
      renderBullet: (index, className) =>
        `<span data="${index}" class="swipable-slider_indicator ${className}"></span>`,
    };

    return {
      options,
      skeleton,
      paginationClass,
      ...toRefs(props),
      pagination,
      modules: [Pagination, Navigation],
    };
  },
};
</script>
