<template>
    <section :class="wrapperClass">
        <div class="section-title-wrapper">
            <Skeletor class="section-title" width="30%" />
            <Skeletor class="dosiin_slider-controls" width="20%"/>
        </div>

        <div>
            <ul class="product-list_first_line grid" :class="gridClass">
                <li class="grid-item" v-for="i in number" :key="i">
                    <ProductSkeleton />
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    import { toRefs } from 'vue';
    import ProductSkeleton from '@/components/skeletons/product/ProductItem'; 
    export default {
        components:{
            ProductSkeleton
        },
        props:{
            wrapperClass :{
                type: String ,
                default : ''
            },
            gridClass:{
                type : String,
                default : 'grid-50'
            },
            number : {
                type : Number,
                default : 3
            }
        },
        setup(props) {
            
            return {
                ...toRefs(props)
            }
        },
    }
</script>