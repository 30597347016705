<template>
    <div class="product-item overflow">
        <a class="product-item_img">
            <Skeletor width="100%" :height="layout == 4 ? 105 : 260" />
        </a>
        <dl class="product-item_summary">
            <a>
                <dt class="product-item_brand truncate">
                    <Skeletor width="50%" />
                </dt>
            </a>
            <dd class="product-item_name truncate">
                <Skeletor width="80%" />
            </dd>
            <div class="product-item_sale">
                <Skeletor width="70%" />
            </div>
            <dd class="product-item_price">
                <Skeletor width="50%" />
            </dd>
            <dl class="product-item_social">
                <Skeletor width="70%" />
            </dl>
        </dl>
    </div>
</template>