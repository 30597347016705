<template>
  <div class="product-item overflow" v-if="product">
    <router-link
      class="product-item_img"
      @click.native="clickEvent"
      :to="_productUrl(product)"
      :title="product.product"
    >
      <img
        v-lazy="_productThumbnailSticker(product.main_pair, product.sticker.main_pair)"
        :alt="product.product"
        width="165"
        height="165"
      />
    </router-link>
    <dl class="product-item_summary">
      <router-link :to="_brandUrl(product.seo_name_company)" :title="product.company">
        <dt class="product-item_brand truncate" v-text="product.company"></dt>
      </router-link>
      <router-link
        :to="_productUrl(product)"
        :title="product.product"
        @click.native="clickEvent"
      >
        <dd class="product-item_name truncate" v-text="product.product"></dd>
      </router-link>
      <div class="product-item_sale">
        <template v-if="product.percent_discount > 0">
          <dd class="inline-bl _sale_origin product-item_sale_origin">
            {{ currencyFormat(product.list_price) }}
          </dd>
          <dd class="inline-bl _sale_origin product-item_sale_discount">
            -{{ Math.ceil(product.percent_discount) }}%
          </dd>
        </template>
      </div>
      <dd class="product-item_price">{{ currencyFormat(product.price) }}</dd>
      <dl class="product-item_social" v-if="showRating">
        <star-rating
          :rating="parseInt(product.average_rating)"
          :star-size="layout == 4 ? 12 : 16"
          :read-only="true"
          :show-rating="false"
          text-class="product-tem_social-text"
          inactive-color="#B3B3B3"
          active-color="#FFCC07"
          :star-points="[
            23,
            2,
            14,
            17,
            0,
            19,
            10,
            34,
            7,
            50,
            23,
            43,
            38,
            50,
            36,
            34,
            46,
            19,
            31,
            17,
          ]"
        />
        <dd class="product-item_n-comments" v-if="layout != 4">
          <i
            class="dsi"
            :class="[
              layout != 4 ? ' dsi-s16' : ' dsi-s12',
              product.posts_count > 0 ? 'dsi-act-fill-chat' : 'dsi-fill-chat',
            ]"
            :style="product.posts_count > 0 ? 'filter: opacity(0.7);' : ''"
          ></i>
          <span class="product-tem_social-text" v-text="product.posts_count"></span>
        </dd>
      </dl>
    </dl>
  </div>
</template>

<script>
import { ref, toRefs } from "vue";
import StarRating from "vue-star-rating";

export default {
  components: {
    StarRating,
  },
  props: {
    product: {
      type: Object,
      require: true,
    },
    showRating: {
      type: Boolean,
      default: false,
    },
    algoliaEvent: {
      type: Function,
      default: () => ({}),
    },
  },
  emits: ["clickProductAlgoliaEvent"],
  setup(props, { emit }) {
    const clickEvent = () => {
      emit("clickProductAlgoliaEvent");
    };

    return {
      ...toRefs(props),
      clickEvent,
    };
  },
};
</script>

<style>
.vue-star-rating-rating-text {
  margin-top: 0 !important;
}
</style>
